<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    @ok="close()"
    @cancel="close()"
  >
    <div class="modal-height-adjust">
      <messages />
      <b-row>
        <b-col>
          <b-overlay :show="processing" variant="transparent" blur="5px" opacity="1">
            <kendo-grid
              id="notifications"
              :data-items="notificationConfigureData"
              :resizable="true"
              :columns="columns"
            >
              <template v-slot:notificationToggle="{ props }">
                <td style="display:flex;justify-content:center;align-items;center;">
                  <k-switch
                    :size="'small'"
                    v-model="props.dataItem.notificationEnabled"
                    :id="'toggle-' + props.dataItem.notificationTypeID"
                    @change="onNotificationToggleChange"
                    :emitOnMount="false"
                  />
                </td>
              </template> </kendo-grid
          ></b-overlay>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import ModalMessages from "../../containers/navs/ModalMessages.vue";
import { Grid } from "@progress/kendo-vue-grid";
import NotificationMixin from "../../mixins/NotificationsMixin.vue";
import { mapMutations } from "vuex";
// import Switches from "vue-switches";
import { Switch } from "@progress/kendo-vue-inputs";

export default {
  name: "NotificationConfigureModal",
  components: {
    messages: ModalMessages,
    "kendo-grid": Grid,
    // ToggleSwitch: Switches,
    "k-switch": Switch,
  },
  mixins: [NotificationMixin],

  data: () => ({
    isVisible: false,
    title: "",
    columns: [
      { field: "notificationType", title: "Notification Type" },
      {
        field: "notificationToggle",
        title: "Active",
        cell: "notificationToggle",
      },
    ],
    notificationConfigureData: [],
  }),

  async created() {
    this.getNotificationConfigureList();
  },
  methods: {
    ...mapMutations(["setModalInfoMessage", "setModalDangerMessage"]),

    async show(opts = {}) {
      this.title = opts.title;

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction;
      }

      this.isVisible = true;
    },
    close() {
      if (this.exitFunction) {
        this.exitFunction();
      }
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      this.isVisible = false;
    },
    async getNotificationConfigureList() {
      const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
      if (currentCompany?.aspNetUserID) {
        await this.getNotificationConfigureData(currentCompany.aspNetUserID);
      }
    },
    async onNotificationToggleChange() {
      this.setModalDangerMessage("");
      this.setModalInfoMessage("");
      const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
      if (currentCompany?.aspNetUserID) {
        await this.setNotificationConfigure({
          aspNetUserID: currentCompany.aspNetUserID,
          notificationConfiguration: this.notificationConfigureData,
        }).then(() => {
          this.getNotificationConfigureList();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}

.modal-height-adjust {
  max-height: 560px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
}
</style>
