<template>
  <div class="row">
    <div class="col">
      <div class="row" style="margin-bottom: 1rem">
        <div class="col-lg-6">
          <h3 class="dashboardWelcomeHeader">Notifications</h3>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <b-button class="btn-blue me-2" @click="notificationConfigure">Configure</b-button>
          <b-button class="btn-blue" @click="acknowledgeAllNotifications()"
            >Acknowledge All</b-button
          >
        </div>
      </div>
      <b-card style="border-radius: 10px; margin-top: 1.7rem">
        <kendo-grid
          id="notifications"
          :data-items="notifications"
          :resizable="true"
          :columns="columns"
          :selected-field="selectedField"
          @selectionchange="onSelectionChange"
          @headerselectionchange="onHeaderSelectionChange"
          @rowclick="onRowClick"
        >
          <template v-slot:acknowledgedTemplate="{ props }">
            <td v-if="props.dataItem.dateAcknowledgedHumanized == 'Never'">
              <div>
                <b-p
                  style="text-decoration: underline; cursor: pointer"
                  @click="acknowledgeNotificationAndReload(props.dataItem)"
                >
                  Acknowledge
                </b-p>
              </div>
            </td>
            <td v-else>
              {{ props.dataItem[props.field] }}
            </td>
          </template>
          <template v-slot:notificationTextTemplate="{ props }">
            <td>
              <div>
                <b-link tag="a" :to="props.dataItem.notificationURL">
                  {{ props.dataItem.notificationText }}
                </b-link>
              </div>
            </td>
          </template>
        </kendo-grid>
      </b-card>
      <NotificationConfigureModal ref="NotificationConfigureModalRef"></NotificationConfigureModal>
    </div>
  </div>
</template>
<script>
import { Grid } from "@progress/kendo-vue-grid";
import NotificationMixin from "../../mixins/NotificationsMixin.vue";
import { bus } from "../../main";
import NotificationConfigureModal from "../../components/Modals/NotificationConfigureModal.vue";

export default {
  props: [],
  components: {
    "kendo-grid": Grid,
    NotificationConfigureModal,
  },
  mixins: [NotificationMixin],

  data() {
    return {
      selectedField: "selected",
      staticColumns: [
        { field: "navigatorNotificationID", title: "ID", width: "50" },
        { field: "notificationType", title: "Type", width: "170" },
        {
          field: "notificationText",
          title: "Message",
          cell: "notificationTextTemplate",
          width: "auto",
        },
        { field: "dateCreatedHumanized", title: "Created", width: "140" },
        {
          field: "dateAcknowledgedHumanized",
          title: "Acknowledged",
          cell: "acknowledgedTemplate",
          width: "140",
        },
      ],
    };
  },
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    await this.getUserNotifications();
    this.notifications.map((item) => {
      return { ...item, selected: false };
    });
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
  },
  computed: {
    areAllSelected() {
      return this.notifications.findIndex((item) => item.selected === false) === -1;
    },
    columns() {
      return [
        { field: "selected", width: "50px", headerSelectionValue: this.areAllSelected },
        ...this.staticColumns,
      ];
    },
  },
  methods: {
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.notifications = this.notifications.map((item) => {
        return { ...item, selected: checked };
      });
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    onRowClick(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    async acknowledgeNotificationAndReload(notification) {
      await this.acknowledgeNotification(notification);
      await this.getUserNotifications();
      this.notifications.map((item) => {
        return { ...item, selected: false };
      });
      bus.$emit("notificationsUpdated");
    },
    async acknowledgeAllNotifications() {
      const currentCompany = JSON.parse(localStorage.getItem("currentCompany"));
      await this.acknowledgeAllNotificationsForUser(currentCompany.aspNetUserID);
      await this.getUserNotifications();
      this.notifications.map((item) => {
        return { ...item, selected: false };
      });
      bus.$emit("notificationsUpdated");
    },
    notificationConfigure() {
      this.$refs.NotificationConfigureModalRef.show({
        title: "Configure Notifications",
        exitFunction: async () => {
          await this.getUserNotifications();
          this.notifications.map((item) => {
            return { ...item, selected: false };
          });
          bus.$emit("notificationsUpdated");
        },
      });
    },
  },
};
</script>
